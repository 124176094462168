import gql from 'graphql-tag'

export const GetUsersGQL = gql`
  query ($filter: UserFilter) {
    getUsers(filter: $filter) {
      totalUsers
      users {
        id
        uid
        email
        phone
        company
        createdTime
        serviceLevel
        userBalance {
          id
          balance
        }
        config {
          marketplace {
            enabled
            fee
          }
          pinCode {
            enabled
          }
          podSignature {
            enabled
          }
          bulk4PL {
            enabled
            fee
            extraCostOnBasePrice {
              providers {
                provider
                extraCost
              }
            }
            chargeMerchantFixedPricePerOrder {
              enabled
              price
            }
          }
          selfManaged {
            enabled
            fee
            groupCode
          }
          fixedPrice {
            bulkOrder {
              enabled
              fee
              pricePerStopSamePostalCode
            }
            standardOrder {
              enabled
              fee
              pricePerStopSamePostalCode
            }
          }
          priceMatrix {
            enabled
            default
            matrix {
              vehicleType
              deliveryType
              distanceFromPickup
              size
              pickupTime
              price
            }
          }
          qrCode {
            enabled
            isRequiredToScanWhenPickup
          }
          batchingTiming {
            enabled
            nextdayCutoffTime
            samedayCutoffTime
            sameDayPickupTimeWithinXHours
            sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries
          }
          providerMatching {
            enabled
            defaultExcludeProviders
            deliveryTypes {
              type
              excludeProviders
            }
          }
          placeOrderConfig {
            minimumTimeBetweenCurrentTimeAndPickupTimeInMin {
              enabled
              default {
                value
              }
              deliveryTypes {
                value
                type
              }
            }
            deliveryWindows {
              enabled
              allowedFixedWindows {
                start
                end
              }
            }
          }
          batchingOptions {
            batchingSplitWhenLate {
              enabled
              currentTimePassStartWindowThresholdInMin
              minimumLengthFromCurrentTimeToEndWindowToSplitInMin
            }
            batchingNextDay {
              enabled
            }
          }
          rerace {
            reraceThreshold {
              enabled
              totalDeliveriesInRoute {
                from
                to
                valueInMinute
              }
            }
            reraceProviderNotMoreThanXValueCompareToLalamovePrice {
              enabled
              value
            }
          }
          sms {
            pricePerStop
            alwaysEnabledSms
            notifyOrderToRecipient {
              smsContent
            }
          }
          autoTip {
            enabled
            intervalXMinsAfterPickupTime
            tipAmountPerInterval
            maxTotalTipAmount
          }
          antiStacking {
            enabled
            excludeProviders
          }
          providerApiKey {
            providers {
              name
              key
              secret
              enabled
            }
          }
        }
      }
    }
  }
`
