import { Vue } from 'nuxt-property-decorator'

export enum NODE_ENV {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  TEST = 'test',
  PRODUCTION = 'production',
}

export type V1RidaApiLocation = {
  lat: string
  lng: string
}

export type V1RidaApiUserBalance = {
  id: string
  userid: string
  balance: string
}

export type V1RidaApiAddress = {
  id: string
  objectId: string
  vendorId: string
  address: string
  postcode: string
  name: string
  phone: string
  updatedTime: string
  createdTime: string
}

export type V1RidaApiUser = {
  serviceLevel?: string
  email: string
  phone: string | null
  firstName: string | null
  lastName: string | null
  uid: string
  role: string
  id: number
  company: string | null
  createdTime: string
  billing: string
  userBalance?: V1RidaApiUserBalance
  apiKey?: {
    appId: string
    clientSecret: string
  }
  config: {
    marketplace: {
      enabled: boolean
      fee: string
      requiredAdditionalCost: boolean
    }
    pinCode: {
      enabled: boolean
    }
    podSignature: {
      enabled: boolean
    }
    bulk4PL: {
      enabled: boolean
      fee: string
      extraCostOnBasePrice: {
        providers: {
          provider: string
          extraCost: string
        }[]
      }
      chargeMerchantFixedPricePerOrder: {
        enabled: boolean
        price: string
      }
    }
    selfManaged: {
      enabled: boolean
      fee: string
      groupCode: string | null
    }
    fixedPrice: {
      bulkOrder: {
        enabled: boolean
        fee: string
        pricePerStopSamePostalCode: string | null
      }
      standardOrder: {
        enabled: boolean
        fee: string
        pricePerStopSamePostalCode: string | null
      }
    }
    priceMatrix: {
      enabled: boolean
      default: string
      matrix: {
        vehicleType?: string[]
        deliveryType?: string[]
        distanceFromPickup?: number[][]
        size?: number[][]
        pickupTime?: string[][]
        price: string
      }[]
    }
    qrCode: {
      enabled: boolean
      isRequiredToScanWhenPickup: boolean
    }
    batchingTiming: {
      enabled: boolean
      nextdayCutoffTime: string | null
      samedayCutoffTime: string | null
      sameDayPickupTimeWithinXHours: number | null
      sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries: number | null
    }
    providerMatching: {
      enabled: boolean
      defaultExcludeProviders: string[]
      deliveryTypes: {
        type: string
        excludeProviders: string[]
      }[]
    }
    placeOrderConfig: {
      minimumTimeBetweenCurrentTimeAndPickupTimeInMin: {
        enabled: boolean
        default: {
          value: number
        }
        deliveryTypes: {
          type: string
          value: number
        }[]
      }
      deliveryWindows: {
        enabled: boolean
        allowedFixedWindows: {
          start: string
          end: string
        }[]
      }
    }
    batchingOptions: {
      batchingSplitWhenLate: {
        enabled: boolean
        currentTimePassStartWindowThresholdInMin: number
        minimumLengthFromCurrentTimeToEndWindowToSplitInMin: number
      }
      batchingNextDay: {
        enabled: boolean
      }
    }
    rerace: {
      reraceThreshold: {
        enabled: boolean
        totalDeliveriesInRoute: {
          from: number | null
          to: number | null
          valueInMinute: number
        }[]
      }
      reraceProviderNotMoreThanXValueCompareToLalamovePrice: {
        enabled: boolean
        value: string
      }
    }
    sms: {
      pricePerStop: string
      alwaysEnabledSms: boolean
      notifyOrderToRecipient: {
        smsContent: string
      }
    }
    autoTip: {
      enabled: boolean
      intervalXMinsAfterPickupTime: number
      tipAmountPerInterval: string
      maxTotalTipAmount: string
    }
    antiStacking: {
      enabled: boolean
      excludeProviders: string[]
    }
    providerApiKey: {
      providers: {
        name: string
        key: string
        secret: string
        enabled: boolean
      }[]
    }
  }
}

export type V1RidaApiGetUserInfoResponse = {
  getUserInfo: V1RidaApiUser
}

export type V1RidaApiTip = {
  id: number
  amount: string
  createdAt: string
  createdBy: V1RidaApiUser | null
}

export type V1RidaApiGetMerchantsResponse = {
  getMerchants: V1RidaApiUser[]
}

export type V1RidaApiGetAllUsersResponse = {
  getUsers: {
    totalUsers: number
    users: V1RidaApiUser[]
  }
}

export type V1RidaApiItem = {
  category: string
  notes: string
  size: number
  customId: string
}

export type V1RidaApiWaypoint = {
  id?: number
  address1: string
  address2: string
  status?: string | null
  location: V1RidaApiLocation
  name: string
  phone: string
  type: string
  pod: string | null
  postcode: string
  podTimestamp: string | null
  items?: V1RidaApiItem[] | null
  pinCode: string | null
  qrCode: string | null
  trackingUrl?: string | null
  providerTrackingUrl?: string | null
  failedReason?: string | null
  additionalDriverEquipment: string | null
}

export type V1RidaApiDriverInfo = {
  name: string
  phone: string
  photo: string
  platenumber: string
  location?: V1RidaApiLocation
  trackingUrl?: string | null
}

export type V1RidaApiOrder = {
  objectId: string
  code: string
  routeId: number | null
  pickupTime: string
  serviceType: string
  status: string
  completedAt: string
  createdAt: string
  updatedAt: string
  user?: V1RidaApiUser
  waypoints: V1RidaApiWaypoint[]
  delivery?: {
    deliveryId: string
    price: string
    provider: string
    type: string
  }

  // eslint-disable-next-line
  deliveries: V1RidaApiDelivery[]
  driverInfo?: V1RidaApiDriverInfo
}

export type V1RidaApiPremiumDelivery = {
  id: string
}

export type V1RidaApiDelivery = {
  id: string
  objectId: string
  code: string
  orderId: string
  status: string
  routeId: string
  deliveryWindow?: {
    start: string
    end: string
  }
  waypoints: V1RidaApiWaypoint[]
  premiumDeliveries?: V1RidaApiPremiumDelivery[]
  order: V1RidaApiOrder
  delivery?: {
    deliveryId: string
    price: string
    provider: string
  }
  driverInfo?: V1RidaApiDriverInfo
  completedAt?: string
  outletName?: string
}

export type V1RidaApiDeliveryComment = {
  id: string
  comment: string
  commentedBy: V1RidaApiUser
  createdAt: string
}

export type V1RidaApiGetOrdersResponse = {
  getOrders: {
    orders: V1RidaApiOrder[]
    totalOrders: number
    totalCompletedOrders: number
    totalOngoingOrders: number
    totalPendingOrders: number
    totalCancelledOrders: number
    providers: string[]
    merchants: V1RidaApiUser[]
  }
}

export type V1RidaApiGetOrderIdResponse = {
  getOrderByObjectId: V1RidaApiOrder
}

export type VForm = Vue & {
  validate: () => boolean
  resetValidation: () => boolean
  reset: () => void
}

export type V1RidaApiProviderInfo = {
  email: string
  phone: string
  text1: string
  supportLink: string
}

export type V1RidaApiProvider = {
  id: string
  name: string
  image: string
  imageHeight: string
  providerInfo: V1RidaApiProviderInfo
}

export type V1RidaApiGetProvidersResponse = {
  getProviders: V1RidaApiProvider[]
}

export type V1RidaApiCreditTransaction = {
  id: number
  userId: string
  user: string
  type: string
  createdTime: string
  amount: string
  description: string | null
  referenceId: string
  order: V1RidaApiOrder
}

export type V1RidaApiGetCreditTransactionsByUserIdResponse = {
  getCreditTransactionsByUserId: {
    totalItems: number
    transactions: V1RidaApiCreditTransaction[]
  }
}

export type V1RidaApiCreditType = {
  id: string
  name: string
  isNegative: boolean
  isPositive: boolean
  isDescriptionRequired: boolean
}

export type V1RidaApiGetRegisteredAddressesResponse = {
  getRegisteredAddresses: V1RidaApiAddress[]
}

export type V1RidaApiGetCreditTypeResponse = {
  getCreditTypes: V1RidaApiCreditType[]
}

export type V1RidaGetUserInfoByUidResponse = {
  getUserInfoByUid: V1RidaApiUser
}

export type V1RidaApiPoolDelivery = {
  id: string
  userid: string
  orderid: string
  batchid: string
  mergedorders: string
  pickuptime: string
  servicetype: string
  deliverywindow: string
  waypoints: string
  price: string
  status: string
  createdtime: string
  updatedtime: string
  order: V1RidaApiOrder
  user: V1RidaApiUser
  delivery: V1RidaApiDelivery
}

export type V1RidaApiGetPoolOrdersForOrderObjectIdResponse = {
  getPoolOrdersForOrderObjectId: V1RidaApiPoolDelivery[]
}

export type V1RidaApiProviderDelivery = {
  providerDeliveryId: string
  waypoint: V1RidaApiWaypoint
  order: V1RidaApiOrder
}

export type V1RidaApiRaceDelivery = {
  id: string
  orderId: string
  price: string
  deliveryProvider: string
  deliveryId: string
  deliveryDriver: V1RidaApiDriverInfo | null
  status: string
  tips: V1RidaApiTip[]
  providerDeliveries: V1RidaApiProviderDelivery[]
  createdTime: string
  updatedTime: string
}

export type V1RidaApiGetRacingProvidersByDeliveryObjectIdReponse = {
  getRacingProvidersByDeliveryObjectId: V1RidaApiRaceDelivery[]
}

export type V1RidaApiGetUnassignedPlannedRouteResponse = {
  getUnassignedPlannedRoute: {
    data: string
  }
}

export type V1RidaApiRoute = {
  id: number
  status: string
  pickupTime: string
  assignedTime: string | null
  user: V1RidaApiUser
  racings: (V1RidaApiRaceDelivery & { totalTipAmount: string })[]
  stops: (V1RidaApiWaypoint & {
    delivery: V1RidaApiDelivery | null
    status?: string | null
  })[]
}

export type V1RidaApiGetRoutesResponse = {
  getRoutes: {
    routes: V1RidaApiRoute[]
    totalRoutes: number
    merchants: V1RidaApiUser[]
  }
}

export type V1RidaApiGetPoolDeliveriesForDeliveryObjectIdResponse = {
  getPoolDeliveriesForDeliveryObjectId: V1RidaApiDelivery[]
}

export type V1RidaApiGetDeliveriesByOrderObjectIdResponse = {
  getDeliveriesByOrderObjectId: V1RidaApiDelivery[]
}

export type V1RidaApiHistory = {
  id: number
  deliveryId: number
  fromDeliveryStatus: string | null
  toDeliveryStatus: string | null
  comment: string | null
  createdAt: string
  createdById: string | null
  fromMergedDeliveries: V1RidaApiDelivery[] | null
  toMergedDeliveries: V1RidaApiDelivery[] | null
  createdBy: V1RidaApiUser | null
}

export type V1RidaApiGetDeliveryHistoryByObjectIdResponse = {
  getDeliveryHistoryByObjectId: {
    delivery: V1RidaApiDelivery
    histories: V1RidaApiHistory[]
  }
}

export type V1RidaApiGetDeliveryByObjectIdResponse = {
  getDeliveryByObjectId: V1RidaApiDelivery
}

export type V1RidaApiGetDeliveryCommentsByObjectIdResponse = {
  getCommentByDeliveryObjectId: {
    total: number
    comments: V1RidaApiDeliveryComment[]
  }
}

export type V1RidaApiGetSidebarNotificationResponse = {
  getSidebarNotification: {
    totalIncompleteDeliveries: number
  }
}

export type V1RidaApiGetIncompleteDeliveriesResponse = {
  getIncompleteDeliveries: {
    total: number
    deliveries: V1RidaApiDelivery[]
  }
}

export type V1RidaApiServiceLevel = {
  id: string
  name: string
}

export type V1RidaApiUserRole = {
  id: string | null
  name: string
}

export type V1RidaApiUserBillingType = {
  id: string
  name: string
}

export type V1RidaApiGetUserServiceLevelsResponse = {
  getUserServiceLevels: V1RidaApiServiceLevel[]
}

export type V1RidaApiGetUserRolesResponse = {
  getUserRoles: V1RidaApiUserRole[]
}

export type V1RidaApiGetUserBillingTypesResponse = {
  getUserBillingTypes: V1RidaApiUserBillingType[]
}

export type V1RidaApiCloneDeliveryByObjectIdResponse = {
  cloneDeliveryByObjectId: {
    pickupTime: string
    vehicleType: string
    deliveryType: string
    userObjectId: string
    window: string | null
    stops: V1RidaApiWaypoint[]
  }
}

export type V1RidaApiGetDeliveryQuotationResponse = {
  getDeliveryQuotation: {
    ridaQuotation: {
      amount: string
      perStops: string[]
      lineItems: {
        name: string
        amount: string
      }[]
    }
    providerQuotation: {
      providers: {
        name: string
        amount: string
      }[]
    }
  }
}

export type V1RidaApiPlaceDeliverOrderResponse = {
  placeDeliveryOrder: {
    deliveries: {
      code: string
      objectId: string
    }[]
  }
}

export type V1RidaApiGetPodPresignedUrlResponse = {
  getPodPresignedUrl: {
    ridaPresignedUrls: string[]
    dlvrdPresignedUrl: string
    dlvrdDocumentId?: string
  }
}

export type V1RidaApiReraceDeliveryByObjectIdResponse = {
  reraceDeliveryByObjectId: {
    warningMessages: string[]
  }
}

export type V1RidaApiOptimizeClonedDeliveryResponse = {
  optimizeClonedDelivery: {
    stopIndex: number[]
  }
}

export type V1RidaApiCancelDeliveryByObjectIdResponse = {
  cancelDeliveryByObjectId: {
    warningMessages: string[]
  }
}

export type V1RidaApiCancelRouteByDeliveryObjectIdResponse = {
  cancelRouteByDeliveryObjectId: {
    warningMessages: string[]
  }
}

export type V1RidaApiUpdateProviderDetailsForDeliveryResponse = {
  updateProviderDetailsForDelivery: {
    warningMessages: string[]
  }
}
