import { Dayjs } from 'dayjs'

import { GetRoutesGQL } from '../graphql/route/GetRoutes'
import { ApolloService } from './Apollo'
import { DEFAULT_FORMAT } from './Constant'
import { V1RidaApiGetRoutesResponse } from './Type'

class Route {
  public static async getRoutes(filterOptions: {
    startDate: Dayjs
    endDate: Dayjs
    userId?: number[]
    itemsPerPage: number
    pageNumber: number
    searchTerm: string
    statuses: string[]
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetRoutesResponse>({
        query: GetRoutesGQL,
        variables: {
          filter: {
            startDate: filterOptions.startDate.format(DEFAULT_FORMAT.DATE),
            endDate: filterOptions.endDate.format(DEFAULT_FORMAT.DATE),
            userId: filterOptions.userId,
            itemsPerPage: filterOptions.itemsPerPage,
            pageNumber: filterOptions.pageNumber,
            searchTerm: filterOptions.searchTerm,
            statuses: filterOptions.statuses,
          },
        },
      })

    return response.data
  }
}

export { Route as RouteService }
