import gql from 'graphql-tag'

export const GetDeliveryByObjectIdGQL = gql`
  query ($objectId: String!) {
    getDeliveryByObjectId(objectId: $objectId) {
      order {
        pickupTime
        serviceType
        user {
          company
          email
          serviceLevel
        }
        delivery {
          type
          price
        }
        createdAt
        updatedAt
      }
      id
      objectId
      routeId
      code
      status
      delivery {
        price
        provider
        deliveryId
      }
      deliveryWindow {
        start
        end
      }
      deliveryCharges {
        objectId
        amount
        label
      }
      premiumDeliveries {
        id
      }
      waypoints {
        id
        type
        address1
        address2
        trackingUrl
        providerTrackingUrl
        status
        failedReason
        location {
          lat
          lng
        }
        items {
          notes
          size
          category
          customId
        }
        name
        phone
        pod
        podTimestamp
        pinCode
        qrCode
      }
      driverInfo {
        name
        phone
        photo
        plateNumber
        trackingUrl
        location {
          lat
          lng
        }
      }
      completedAt
      outletName
    }
  }
`
